import { showAlert } from '../../util/alert.services';

import { api } from "../../util/config";
import { headers } from "../../util/config";

const axios = require("axios");


const signInWithEmailAndPassword = async (mobile, pin) => {
  try {
    let response = await axios.post(
      api.adminLogin,
      { mobile, pin, user_type: "restaurant_admin" },
      headers
    );
    console.log("response.data.data ==>>>> ",response.data.data)
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("app-key", response.data.data.app_id.app_key);
    localStorage.setItem("app-name", response.data.data.app_id.name);
    localStorage.setItem("app-id", response.data.data.app_id._id);
    localStorage.setItem("restaurant_id", response.data.data.restaurant._id);
    localStorage.setItem("restaurant_name", response.data.data.restaurant.name);
    return response.data.data ? response.data.data : {};
  } catch (error) {
    if (error.response) {
      showAlert(error.response.data.message, "e");
      //throw (error.error.response.data.message);
    }
  }
};

export { signInWithEmailAndPassword };
